<template>
  <div class="c-legal-description" :class="{'c-legal-description_modal': styleModal == true}">
    <h2 class="c-legal-description__title" :class="{'c-legal-description__title_modal-text': styleModal == true}">
      Terms of Use
    </h2>
    <!-- <h2>
      as your digital asset wealth management partner <span class="c-legal-description__content_bold"></span>
    </h2> -->
    <p class="c-legal-description__content-term-of-use">
      The Sparrow Group (which includes Sparrow Holdings Pte. Ltd. and its subsidiaries, affiliates, associated companies and jointly controlled entities) (collectively <span class="c-legal-description__content_bold">"Sparrow"</span>, <span class="c-legal-description__content_bold">"We"</span> or <span class="c-legal-description__content_bold">"Our"</span>) operates SparrowExchange.com (<span class="c-legal-description__content_bold">"Website"</span>) that: (i) has an interactive online peer-to-peer platform (<span class="c-legal-description__content_bold">"Sparrow Platform"</span>) for cryptocurrency enthusiasts which facilitates the buying and selling of Dual Currency Product, (ii) operates the Sparrow Wallet; (iii) offers the ConvertNOW facility, and (iv) offers other products, features and other services operated by Sparrow (collectively, <span class="c-legal-description__content_bold">"Services"</span>).
    </p>
    <p class="c-legal-description__content-term-of-use">
      These Terms of Use (<span class="c-legal-description__content_bold">"Terms"</span>) govern each User’s use of the Website or any Services. Please read the entire Terms carefully. By accessing and/or registering with the Website, you agree that you have read, understood and agree to be bound by the Terms as set out herein and the Privacy and Data Protection Policy (collectively the <span class="c-legal-description__content_bold">"Agreement"</span>), as amended, varied and/or supplemented from time to time.
    </p>
    <p class="c-legal-description__content-term-of-use">
      If you do not agree to the Agreement, please do not visit, access, or use the Website or Services, or create an account. The electronic acceptance, acknowledgement of the Agreement or continued use of the Website or Services shall constitute an acceptance of the Agreement, which shall take effect upon your first access of the Website or Services. Any failure to comply with the Agreement may constitute a violation of applicable securities law. Sparrow reserves the right to suspend, disable or cancel your account at any time and without notice to you if it deems that you have failed to comply with the Agreement.</p>
    <p class="c-legal-description__content-term-of-use">
      Sparrow is currently licensed by the Monetary Authority of Singapore (“<span class="c-legal-description__content_bold">MAS</span>”) to provide Digital Payment Token (“<span class="c-legal-description__content_bold">DPT</span>”) services as a Major Payment Institution under the Payment Services Act No. 2 of 2019 in August 2022.
    </p>
    <!-- START	INTERPRETATION -->
    <p class="c-legal-description__title-term-of-use c-legal-description__title_bold c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      1.	<span class=" c-legal-description__title_underline">INTERPRETATION</span>
    </p>
    <p class="c-legal-description__content-term-of-use">
      1.1 In addition to terms defined elsewhere in these Terms, the following definitions shall apply throughout these <span class="c-legal-description__content-padding24">Terms, unless the contrary intention appears:</span>
    </p>
    <div class="c-legal-description__content-term-of-use c-legal-description__content-padding24">
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“ConvertNOW”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the ConvertNOW facility offered on the Website which Users may convert supported cryptocurrencies into other supported cryptocurrencies;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Designated Bank Account”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the bank account designated by Sparrow into which Users may deposit Fiat Currencies for purposes of conversion to Sparrow Dollars that may be used on the Sparrow Platform;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Digital Assets”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means any asset that is issued and/or transferred using distributed ledger, blockchain technology or any equivalent technologies, including, but not limited to, any digital payment tokens, cryptocurrencies, coins, tokens and any functionally equivalent digital subjects, excluding any Fiat Currency. It also includes “Sparrow Dollar” which is a representative token issued by Sparrow Digital but does not include the Dual Currency Product; </div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“DPT”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means any digital representation of value that is expressed as a unit, not denominated in any currency or pegged to any currency, intended to be a medium of exchange accepted by the public as payment and can be transferred, stored or traded electronically;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Dual Currency Product”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means an instrument that is provided by Sparrow being a time-limited derivative created over Bitcoin or Ethereum which may be subscribed for by the Users and designed to capitalize on investment opportunities and optimize profits during market fluctuations and volatility;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">"Entity"</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means any one of the following:</div>
        <div class="c-legal-description__content_list-text c-legal-description__content_list-text-list"> (i)	 a sole proprietorship</div>
        <div class="c-legal-description__content_list-text c-legal-description__content_list-text-list"> (ii)	 a private limited company;</div>
        <div class="c-legal-description__content_list-text c-legal-description__content_list-text-list"> (iii) a public limited company;</div>
        <div class="c-legal-description__content_list-text c-legal-description__content_list-text-list"> (iv)	 a general, limited or limited liability partnership;</div>
        <div class="c-legal-description__content_list-text c-legal-description__content_list-text-list"> (v)   a trust;</div>
        <div class="c-legal-description__content_list-text c-legal-description__content_list-text-list"> (vi)  a foundation;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Fees”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the Transaction Fees and Withdrawal Fees;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Fiat Currency”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means any money denominated in a fiat currency, excluding (a) Bitcoins, (b) stable coins, and (c) any other Digital Assets that are not directly issued by any governments or central banks as fiat currencies in the relevant jurisdictions;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Force Majeure Event”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means any act of God, labour action or dispute, war declared or undeclared, invasion, act of terrorism or sabotage, cyber-terrorism, disruption to the security, integrity, and availability of the internet or blockchain networks, blockade, civil unrest, man-made disaster, government restraint or action, perils of the sea, embargo, disease epidemic, pandemic, public health crisis, radioactive, chemical or biological contamination, accident, nationalization, expropriation, currency restrictions, acts of state, interruptions, IT Malfunction, market data error or other power failure, regulatory changes and for the avoidance of doubt includes any Communications Failure, or any other cause, event or circumstance, whether of the kind specifically enumerated above or otherwise, the occurrence and the effect of which is beyond the reasonable control of, and could not have been reasonably prevented by, the Party whose obligation it affects, notwithstanding the exercise of reasonable foresight, at reasonable cost and by the exercise of reasonable diligence on the part of that Party and which renders due performance of an obligation under this Agreement illegal or impracticable;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“IT Malfunction”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means any technical problems, power or system failures, malfunctions, internet or technological breakdown, communication line failures, high internet traffic or demand, related issues, security breaches or any similar technical problems or defects experienced;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“KYC Programme”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the anti-money laundering, countering of the financing of terrorism and know-your-client programme implemented by Sparrow, as may be amended, varied and/or supplemented from time to time;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Losses”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means all costs, damages, losses, liabilities (including tax liabilities), charges, actions, claims, demands and expenses in connection with any action, suit or proceeding, investigations, judgments, orders whether involving a third party claim or a claim solely between the Parties and includes any incidental, indirect or consequential damages (including any ordinary, direct, indirect, consequential, incidental, special, punitive or exemplary damages), losses (direct or consequential), liabilities or expenses, and any lost profits or diminution in value;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Parties”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the User and Sparrow;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Privacy and Data Protection Policy”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the personal data and privacy policies adopted by Sparrow Digital from time to time which is available on the Sparrow Platform; </div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Representative”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means, in relation to a person, any director, officer or employee of, and any adviser or consultant to, that person;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“SFA”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the Securities and Futures Act 2001 of Singapore;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Sparrow Dollar”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means a cryptographic blockchain-based digital information unit token used to pay for the premium for the Dual Currency Product and to act as a settlement unit of measure for the Dual Currency Product. The Sparrow Dollar is not a Digital Asset;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Sparrow Platform”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the peer-to-peer fully customisable cryptocurrency trading platform, including TradePROTECT and TradeBOOST, operated by Sparrow and accessible via the Website;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Sparrow Wallet”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means the digital wallet of each User hosted on the Website and maintained by Sparrow that is held in the name, or associated with the unique identifier of the User and is used for storing each User’s Digital Assets or for the initiation of a payment order and/or the execution of a payment transaction;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Supported Digital Assets”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means such Digital Assets as may be supported on the Website or accepted by Sparrow as a form of payment from time to time;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Underlying Digital Assets”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means either Bitcoin or Ether underlying the Dual Currency Product, which are cryptocurrencies and do not constitute capital markets products under the Securities and Futures Act 2001 of Singapore;</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“User” or “you”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means a user of the Website; and</div>
      </div>
      <div class="c-legal-description__content_list">
        <div class="c-legal-description__content_list-title">“Website”</div>
        <div class="c-legal-description__content_list-equal">:</div>
        <div class="c-legal-description__content_list-text"> means SparrowExchange.com.</div>
      </div>
    </div>
    <p class="c-legal-description__content-term-of-use">1.2 In these Terms:</p>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">1.2.1</div>
      <div class="c-legal-description__content_list-data"> headings and sub-headings are inserted for convenience only and shall not affect the interpretation and/or construction of these Terms;</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">1.2.2</div>
      <div class="c-legal-description__content_list-data"> singular words include the plural and vice versa, and a word of any gender includes the corresponding words of any other gender;</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">1.2.3</div>
      <div class="c-legal-description__content_list-data"> references to persons or entities include natural persons, bodies corporate, partnerships, trusts and unincorporated and incorporated associations of persons, and shall also include that person’s legal personal representatives, successors and permitted assigns; and</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">1.2.4</div>
      <div class="c-legal-description__content_list-data"> a reference to a statutory provision includes a reference to the statutory provision as modified or re-enacted or both from time to time and any subordinate legislation made under the statutory provision (as so modified or re-enacted) from time to time.</div>
    </div>
    <!-- START SERVICES AND FEES -->
    <p class="c-legal-description__title-term-of-use c-legal-description__title_bold c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      2.	<span class="c-legal-description__title_underline">USE OF SERVICES</span>
    </p>
    <p class="c-legal-description__content-term-of-use">2.1 Sparrow provides the following Services to Users on the Website:</p>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.1.1</div>
      <div class="c-legal-description__content_list-data"> the Sparrow Platform;</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.1.2</div>
      <div class="c-legal-description__content_list-data"> the Sparrow Wallet; </div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.1.3</div>
      <div class="c-legal-description__content_list-data"> the ConvertNOW facility; and</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.1.4</div>
      <div class="c-legal-description__content_list-data"> other products, features and services operated by Sparrow that are incidental to the above.</div>
    </div>
    <p class="c-legal-description__content-term-of-use">2.2 Sparrow Platform</p>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.2.1</div>
      <div class="c-legal-description__content_list-data"> The offer of the Dual Currency Product on the Sparrow Platform is not a regulated activity under the SFA or the PS Act.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.2.2</div>
      <div class="c-legal-description__content_list-data"> A User is not allowed to make any offer of the Dual Currency Product to another User on the Sparrow Platform.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.2.3</div>
      <div class="c-legal-description__content_list-data"> Any fees charged for the use of the Sparrow Platform and the Services will be paid in the form of Supported Digital Assets by the User to Sparrow. </div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.2.4</div>
      <div class="c-legal-description__content_list-data"> Sparrow does not act as agent for the User. Sparrow also does not provide any advice or take part in any part of the User’s investment of the Dual Currency Product on the Sparrow Platform. Any decision by the User to invest in the Dual Currency Product shall solely be the decision of the User.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.2.5</div>
      <div class="c-legal-description__content_list-data"> On the Sparrow Platform, the User may use his account to invest in the Dual Currency Product. Users should ensure that they have sufficient Digital Assets in their Sparrow Wallet to make any transactions on the Sparrow Platform. If the Users make a transaction on the Sparrow Platform without sufficient Digital Assets in the Sparrow Wallet, the transaction will not be processed and Sparrow may in its sole and absolute discretion charge the Users an administrative fee for the failed transaction.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.2.6</div>
      <div class="c-legal-description__content_list-data"> By placing an order on the Sparrow Platform, the User acknowledges and agrees that they consent and authorise Sparrow to transfer such Digital Assets stored in the User’s Sparrow Wallet to any wallets belonging to Sparrow Digital Pte. Ltd. and/or Sparrow Tech Private Limited for the purpose of fulfilling the User’s order.</div>
    </div>
    <p class="c-legal-description__content-term-of-use">2.3 Sparrow Wallet</p>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.3.1</div>
      <div class="c-legal-description__content_list-data"> The User is responsible for the withdrawals or transfer from, or storing in, any Digital Assets, the Sparrow Wallet to another wallet or trading platform and Sparrow will not be responsible or liable for any Digital Assets once the User has withdrawn or transferred them from the Sparrow Wallet to another wallet or trading provider.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.3.2</div>
      <div class="c-legal-description__content_list-data"> The User is solely responsible for ensuring the recipient wallet address is correct prior to submitting any withdrawal or transfer requests.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.3.3</div>
      <div class="c-legal-description__content_list-data"> All instructions for withdrawals and transfers from Sparrow Wallet are irrevocable once sent and Sparrow takes no responsibility for verifying recipient addresses for such withdrawals or transfers. Sparrow is not able to cancel a withdrawal or transfer once it has been made.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.3.4</div>
      <div class="c-legal-description__content_list-data"> The User bears all risk when withdrawing or transferring out of, or storing any Digital Assets in, Sparrow Wallet, especially when withdrawing or transferring to an overseas-based provider. Sparrow is under no obligation to verify a withdrawal or transfer address or identify whether a withdrawal or transfer may be the subject of a scam regardless of whether a scam is known or has been perpetrated before.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.3.5</div>
      <div class="c-legal-description__content_list-data"> Sparrow shall not be liable for any Losses resulting from the use of the Sparrow Wallet except where such Losses arise from fraud by Sparrow.</div>
    </div>

    <p class="c-legal-description__content-term-of-use">2.4 ConvertNOW facility</p>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.4.1</div>
      <div class="c-legal-description__content_list-data"> The operation of the ConvertNOW facility is a regulated activity under the PS Act. Sparrow is currently licensed by the MAS to provide DPT services.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.4.2</div>
      <div class="c-legal-description__content_list-data"> The User may convert Supported Digital Assets into other Supported Digital Assets by using the ConvertNOW facility.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.4.3</div>
      <div class="c-legal-description__content_list-data"> Upon receiving a User’s request for conversion of the User’s Digital Asset(s) to one or more other Digital Asset(s), the User acknowledges and grants Sparrow the right and full discretion to utilise the User’s Digital Assets for conversion and to fulfil the User’s order.</div>
    </div>

    <p class="c-legal-description__content-term-of-use">2.5	Transfer of Digital Assets</p>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.5.1</div>
      <div class="c-legal-description__content_list-data"> The User may transfer the beneficial interest the User holds in the Digital Assets to other Users on the Sparrow Platform (“Transfer”). For the avoidance of doubt, the User will not be able to transfer the beneficial interest the User holds in the Digital Assets to any person who is not a User.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.5.2</div>
      <div class="c-legal-description__content_list-data"> Upon receiving a User’s request for the Transfer (“Transfer Request”), the User acknowledges and grants Sparrow the right and full discretion to utilise the User’s Digital Assets for the Transfer. Sparrow reserves the sole and absolute discretion to reject any Transfer Request without providing any reasons.</div>
    </div>
    <div class="c-legal-description__content_list c-legal-description__content-padding24">
      <div class="c-legal-description__content_list-number">2.5.3</div>
      <div class="c-legal-description__content_list-data"> The User shall be solely responsible for providing the correct information about the recipient. Sparrow shall not be liable for any Losses that the User may incur, whether directly or indirectly, as a result of providing incorrect information about the recipient, including but not limited to the incorrect account name and/or wallet address.</div>
    </div>

    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      3. <span class="c-legal-description__title_underline">FEES</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-bottom-20">
      <div class="c-legal-description__content_list_data-number">3.1</div>
      <div class="c-legal-description__content_list_data-text">Sparrow charges the User the following fees:
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">3.1.1</div>
          <div class="c-legal-description__content_list-data">transaction fees for the investment in the Dual Currency Product on the Sparrow Platform (<span class="c-legal-description__content_bold">"Transaction Fees"</span>); and</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">3.1.2</div>
          <div class="c-legal-description__content_list-data">withdrawal fees for the withdrawal of any Digital Assets from the Sparrow Wallet (<span class="c-legal-description__content_bold">"Withdrawal Fees"</span>).</div>
        </div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">3.2</div>
      <div class="c-legal-description__content_list_data-text">The User may pay the Fees in the form of the Supported Digital Assets and payments will be settled by debiting the relevant Digital Asset balances in the Sparrow Wallet.</div>
    </div>
    <!-- <p class="c-legal-description__content-term-of-use">3.2	The User may pay the Fees in the form of the supported Digital Assets and payments will be settled by debiting the relevant Digital Asset balances in the Sparrow Wallet.</p> -->

    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      4.	<span class="c-legal-description__title_underline">ACCOUNT</span>
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">4.1</div>
      <div class="c-legal-description__content_list_data-text">The User shall be required to register with the Website and create an account before using any of the Services. Only a legal person (individual or Entity) may create an account with Sparrow and be a User. Each individual or Entity shall be entitled to create one account only. The account belongs exclusively to the User and is not transferable. Sparrow may, in its sole and absolute discretion, refuse to create an account for an individual or Entity and/or ban an individual or Entity from creating an account on the Website.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.2</div>
      <div class="c-legal-description__content_list_data-text">The User agrees that he shall successfully complete the KYC Programme prior to being able to create an account on the Website. The User agrees to provide all information and/or documentation which Sparrow may request for in order for Sparrow to fulfil its legal, regulatory and contractual obligations, including but not limited to the information for the purposes of identity verification, the detection of money laundering, terrorist financing, fraud, or any other financial crime, and to create the Sparrow Wallet for the User. Such information could include the passport of the User, telephone number, email address, personal identification number, information regarding the User’s bank account, and such other information that Sparrow may reasonably require. The User confirms that, in providing Sparrow with such information, the information provided is accurate and correct, and agrees that he shall notify and provide Sparrow with the updated information promptly should there be any change in the information provided.
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.3</div>
      <div class="c-legal-description__content_list_data-text">The User acknowledges that Sparrow may from time-to-time require the User to disclose and furnish such information and/or documents to ensure that the User continues to be in compliance with the KYC Programme, or may require the User to undergo the KYC Programme again. The User agrees that he shall comply with all such requests by Sparrow, and Sparrow shall be entitled to suspend and/or terminate the User’s account if the User fails to furnish such information and/or documents or successfully complete the KYC Programme within the period stipulated by Sparrow.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.4</div>
      <div class="c-legal-description__content_list_data-text">Each time the User accesses the Website for its Services, he shall enter his email address and password (or such other security measure, including two-factor authentication and new device authorisation processes, as the Website may from time to time implement). The User shall not adapt or circumvent the systems in place in connection with the Website, nor access the Website other than through the normal use of it. The User agrees that he shall keep the account login credentials, and such other information that may be necessary for accessing his account, confidential and secure at all times. Any breach of security, loss, theft or unauthorized use of the User’s account, email address, password, security information or login credentials must be notified to Sparrow immediately. Sparrow assumes no responsibility for any loss that the User may sustain due to compromise of account login credentials (even where you may not be aware of such unauthorised access) except to the extent Sparrow was grossly negligently in taking reasonable steps to secure the Website and/or the Sparrow Platform. 
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.5</div>
      <div class="c-legal-description__content_list_data-text">The User shall be fully responsible for all information and activity conducted under the User’s account, whether conducted by the User or an authorised third party of the User. In particular, a User shall, if it is an Entity, be responsible for the use of its account by its employees, sub-contractors, agents or other authorized persons. Sparrow may require you to produce evidence that you are authorised to act on behalf of the Entity and you must provide us with any information we reasonably request for this purpose.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.6</div>
      <div class="c-legal-description__content_list_data-text">Sparrow reserves the right to terminate, suspend or restrict the access of the User to his account, Sparrow Wallet and/or the Website and to cease acting on his instructions if there is reasonable suspicion that the person logged into the User’s account is not the User or an authorized person of the User or where Sparrow suspects that the account and/or Sparrow Wallet will be used for illegal, fraudulent or unauthorized uses. </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.7</div>
      <div class="c-legal-description__content_list_data-text">Any payment by the Users in relation to their investment in the Dual Currency Product shall be made via the Sparrow Platform which will settle the payments by debiting and crediting the relevant Digital Asset balances in the Sparrow Wallet.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.8</div>
      <div class="c-legal-description__content_list_data-text">Users may convert Fiat Currency into Sparrow Dollars to facilitate the purchase of Digital Assets by transferring Fiat Currency to the Designated Bank Account from a bank account that is in the User’s name or the User is an authorised signatory of. Upon receiving a User request for conversion of the User’s Fiat Currency to Digital Asset(s), the User acknowledges and grants Sparrow the right and full discretion to utilise the User’s Fiat Currency for conversion and to fulfil the User’s order. Sparrow will then credit the equivalent amount of Sparrow Dollars into the User’s Sparrow Wallet. Sparrow may, at any time, request additional information to verify whether the User is the owner or authorised signatory of a bank account.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.9</div>
      <div class="c-legal-description__content_list_data-text">Users may convert Digital Assets into Fiat Currency only for purposes of withdrawal. Upon receiving a User request for conversion of the User’s Digital Asset(s) to Fiat Currency, the User acknowledges and grants Sparrow the right and full discretion to utilise the User’s Digital Assets for conversion and to fulfil the User’s order. Sparrow will then credit the equivalent amount of Fiat Currency into the User’s bank account. Sparrow may, at any time, request for additional information and/or supporting documents to verify whether the User is the actual owner of the bank account or is duly authorised as a signatory to operate the bank account. </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.10</div>
      <div class="c-legal-description__content_list_data-text">For incoming or out-going transfers to and from the Sparrow Wallet, Sparrow maintains a whitelist of the verified third-party wallets of all its Users to ensure that Digital Assets in the User’s Sparrow Wallet will only be received from or sent to verified third-party wallets. Users will not be able to conduct any incoming or out-going transactions to and from his Sparrow Wallet until a valid active third-party wallet address has been whitelisted.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">4.11</div>
      <div class="c-legal-description__content_list_data-text">Sparrow may, in its sole and absolute discretion, immediately remove any Digital Asset from the Website and/or the Users’ Sparrow Wallet if that Digital Asset is deemed by Sparrow (in its sole and absolute discretion) to:  
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">4.11.1</div>
          <div class="c-legal-description__content_list-data">be in breach of the Terms; </div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">4.11.2</div>
          <div class="c-legal-description__content_list-data">promote or encourage illegal activity; </div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">4.11.3</div>
          <div class="c-legal-description__content_list-data">be harmful, abusive, offensive or illegal or infringes the rights of Sparrow or the User; or</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">4.11.4</div>
          <div class="c-legal-description__content_list-data">constitute a capital markets product, securities-based derivatives contract or a collective investment scheme under the applicable laws of Singapore or any other jurisdiction.</div>
        </div>
      </div>
    </div>

    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      5. <span class="c-legal-description__title_underline">SAFEGUARDING</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-bottom-20">
      <div class="c-legal-description__content_list_data">
        <div class="c-legal-description__content_list_data-number">5.1</div>
        <div class="c-legal-description__content_list_data-text">The User’s Digital Assets will be held by Sparrow in designated trust accounts opened with banks or other duly licensed financial institutions (each, a “Safeguarding Institution”). The funds will be held by Sparrow on behalf of its Users and are segregated and maintained separately from Sparrow’s own corporate funds. You can make a claim for your funds held in the trust accounts even if Sparrow becomes insolvent. Any interest earned from the funds maintained in the trust accounts are payable to Sparrow and will not accrue to you. Your funds in the trust accounts are commingled and pooled with funds received from other users and are exposed to losses or shortfalls in the pooled funds. You acknowledge that you may not be able to recover the full value of your funds in the event of a shortfall in the pooled funds or the insolvency of the Safeguarding Institution.</div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-bottom-20">
      <div class="c-legal-description__content_list_data-number">5.2</div>
      <div class="c-legal-description__content_list_data-text">You confirm and agree, in including for the benefit of the Safeguarding Institutions that:
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">5.2.1</div>
          <div class="c-legal-description__content_list-data">the trust account arrangements do not result in you being a party to any agreement with the Safeguarding Institutions, and you will have no right to enjoy or enforce any benefit under such agreement;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">5.2.2</div>
          <div class="c-legal-description__content_list-data">the Safeguarding Institutions owe no obligation to you and you have no right to claim against the Safeguarding Institutions in relation to any services provided by the Safeguarding Institutions to Sparrow; and</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">5.2.3</div>
          <div class="c-legal-description__content_list-data">Your rights and obligations (if any) in relation to any services provided by Sparrow to you are exclusively against or to Sparrow only, and not against or to the Safeguarding Institutions.</div>
        </div>
      </div>
    </div>

    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      6. <span class=" c-legal-description__title_underline">SUSPENSION, TERMINATION AND CANCELLATION</span>
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">6.1</div>
      <div class="c-legal-description__content_list_data-text">Sparrow may, in its sole and absolute discretion, (a) suspend, refuse to complete, prevent, block, cancel, or terminate any order and/or transaction; (b) “freeze” the Digital Assets in the User’s Sparrow Wallet; (c) suspend, restrict, or terminate the User’s access to the Website and/or any of the Services; (d) refuse to process, or to cancel or reverse, any transaction even after it has been completed, without any obligation to allow the User to reinstate the cancelled transaction on the same terms; (e) deactivate or cancel the User’s account with immediate effect for any reason, including but not limited to the following:
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.1</div>
          <div class="c-legal-description__content_list-data">Sparrow reasonably believes that this is required in order to protect Sparrow’s reputation;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.2</div>
          <div class="c-legal-description__content_list-data">Sparrow is of the opinion that it is required to do so by applicable law, regulation or any court or other authority which Sparrow may be subject to in any jurisdiction;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.3</div>
          <div class="c-legal-description__content_list-data">Sparrow suspects the User to be acting in breach of this Agreement;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.4</div>
          <div class="c-legal-description__content_list-data">Sparrow suspects that the User has multiple accounts;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.5</div>
          <div class="c-legal-description__content_list-data">Sparrow has concerns that a transaction is erroneous (including where the transaction was erroneously made due to technical issues or where there was a pricing error), that the security of the User’s account is compromised, or if there is unusual activity in the User’s account;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.6</div>
          <div class="c-legal-description__content_list-data">Sparrow suspects the User’s account is being used for illegal, fraudulent or unauthorised purposes and/or activities;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.7</div>
          <div class="c-legal-description__content_list-data">Sparrow reasonably suspects or discovers that the User is engaged in money laundering, terrorist financing, fraud, or any other financial crime;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.8</div>
          <div class="c-legal-description__content_list-data">Sparrow reasonably suspects that the transaction involves money laundering, terrorist financing, fraud, or any other type of financial crime, or relates to a prohibited use or a prohibited business;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.9</div>
          <div class="c-legal-description__content_list-data">The User’s account is subject to any pending litigation, investigation, or government proceeding;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.10</div>
          <div class="c-legal-description__content_list-data">The User’s account has been inactive (the account has no activities or active positions) for more than sixty (60) days;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.11</div>
          <div class="c-legal-description__content_list-data">Sparrow perceives that it will encounter a heightened risk of legal or regulatory non-compliance associated with the User’s account should Sparrow continue to offer the User access to the Website;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.12</div>
          <div class="c-legal-description__content_list-data">The User takes any action with the purpose of circumventing Sparrow’s controls; and/or</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">6.1.13</div>
          <div class="c-legal-description__content_list-data">Sparrow perceives that the User had misused the Sparrow Platform in a manner beyond the scope of the Sparrow Platform’s intended purpose,</div>
        </div>
        <div class="c-legal-description__content_list c-legal-description__content-term-of-use-margin-top-20 c-legal-description__content-term-of-use-margin-bottom-20">
          (collectively, the <span class="c-legal-description__content_bold">“Suspicious Events”</span> and each a <span class="c-legal-description__content_bold">“Suspicious Event”</span>).
        </div>
      </div>
    </div>

    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">6.2</div>
      <div class="c-legal-description__content_list_data-text">Should the User’s account be suspended or terminated by Sparrow for any reason, the User may withdraw the assets from his Sparrow Wallet by submitting a withdrawal request via email to Sparrow at support@sparrowexchange.com. Sparrow reserves the right to require the User to complete and satisfy such verification and/or approval procedures to verify the User’s identity and such procedures as may be required to comply with any applicable law or regulation before permitting the User to transfer or withdraw the assets from his Sparrow Wallet.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">6.3</div>
      <div class="c-legal-description__content_list_data-text">Sparrow also reserves the right to modify or discontinue the Website or any Service, and Sparrow will not be liable to Users or to any third party for Losses arising from the termination, suspension or restriction of an account or access to the Website and/or any of the Services in accordance with these Terms.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">6.4</div>
      <div class="c-legal-description__content_list_data-text">The suspension or termination of an account shall not affect the payment of any Fees due for past transactions that have been made on the Website or via any of the Services. </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">6.5</div>
      <div class="c-legal-description__content_list_data-text">Upon termination, the User must provide Sparrow with all reasonable assistance to allow for the transfer of any Digital Assets back to the User. Remaining Digital Assets may either be: (a) transferred to the User’s whitelisted third-party wallet, or (b) converted into a Fiat Currency and withdrawn to the User’s bank account. Sparrow will transfer Fiat Currency as soon as practicable following the User’s request upon termination of the User’s account.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">6.6</div>
      <div class="c-legal-description__content_list_data-text">The User acknowledges that all fees and charges, including but not limited to bank charges and transaction fees, pertaining to the transfer of any Digital Assets back to the User (whether in the form of Digital Assets or in Fiat Currency) due to a suspension or termination or cancellation shall be borne by the User.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      7.	<span class="c-legal-description__title_underline">INVESTIGATION</span>
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">7.1</div>
      <div class="c-legal-description__content_list_data-text">The User acknowledges and agrees that Sparrow shall have the right to, in its sole and absolute discretion, commence investigations into the User’s account and/or any transactions, in any of the Suspicious Events.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">7.2</div>
      <div class="c-legal-description__content_list_data-text">If Sparrow suspects that an account has committed a breach of this Agreement and has commenced investigations pursuant to clause 6.1 above, the User acknowledges and agrees that upon Sparrow’s request and within the time frame designated by Sparrow, the User shall respond to any interrogatories in writing and furnish any information and/or documents requested by Sparrow in connection with any investigation initiated pursuant to clause 6.1 above. The User acknowledges and agrees that he shall neither impede or delay any investigation or proceeding conducted pursuant to this Agreement, nor refuse to comply with any request made pursuant to this Agreement. The User acknowledges and agrees that Sparrow shall have the right to suspend and/or terminate or cancel the User’s account if the User fails to cooperate with the investigation within the date stipulated by Sparrow.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">7.3</div>
      <div class="c-legal-description__content_list_data-text">Following the conclusion of an investigation, Sparrow will, in its sole and absolute discretion, make a determination based upon the weight of the evidence. The User acknowledges and agrees that if the account is determined to have committed a breach of this Agreement, Sparrow shall have the right to terminate the User’s account and take any and all actions pursuant to this Agreement and/or any applicable laws and regulations.</div>
    </div>

    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      8.	<span class="c-legal-description__title_underline">DISCLAIMERS AGAINST LIABILITY</span>
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">8.1</div>
      <div class="c-legal-description__content_list_data-text">Risks in Investing in the Dual Currency Product and Use of Services
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.1.1</div>
          <div class="c-legal-description__content_list-data">The User is aware that Sparrow does not provide any advice on the investment in the Dual Currency Product or the use of any Services. Investment in the Dual Currency Product and the use of the Website or any of the Services is solely the decision of the User.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.1.2</div>
          <div class="c-legal-description__content_list-data">The User is aware that there may be high and speculative risks involved with the investment in the Dual Currency Product or the use any of the Services and that all or parts of the investment may be lost. The User further acknowledges that the User may suffer Losses as a result of the sale of the Underlying Digital Assets arising from the investment in the Dual Currency Product, and the amount of Losses depends on the difference between the strike price and the market price of the Underlying Digital Assets. Sparrow shall not be liable in any way, directly or indirectly, as a result of the orders placed on behalf of the User.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.1.3</div>
          <div class="c-legal-description__content_list-data">Before the User uses the Website or any Services, the User should be aware of the following.
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(i)</div>
              <div class="c-legal-description__content_list-data">Sparrow is currently licensed by the MAS to provide DPT services. However, this does not mean that the User will be able to recover all or any Fiat Currency or Digital Assets the User has paid to Sparrow if Sparrow’s business fails or goes insolvent.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(ii)</div>
              <div class="c-legal-description__content_list-data">The User should not transact in Digital Assets if the User is not familiar with Digital Assets. Transacting in Digital Assets may not be suitable for the User if the User is not familiar with the technology on which the Digital Assets are based.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(iii)</div>
              <div class="c-legal-description__content_list-data">The User should be aware that the value of Digital Assets may fluctuate greatly. The User should buy Digital Assets only if the User is prepared to accept the risk of losing all of the Fiat Currency put into such Digital Assets. </div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(iv)</div>
              <div class="c-legal-description__content_list-data">The trading or holding of Digital Assets involves significant risk. Prices can and do fluctuate on any given day. Due to such price fluctuations, the User may increase or lose value in the User’s Digital Assets at any given moment. Digital Assets may be subject to large swings in value and may even become worthless. There is an inherent risk that Losses will occur as a result of buying, selling, trading, converting, transferring or holding Digital Assets.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(v)</div>
              <div class="c-legal-description__content_list-data">Digital Assets may not be backed by governments or other legal entities, or by commodities such as gold or silver, and are a unique kind of currency, backed by technology and trust. There is no central bank that can take corrective measures to protect the value of Digital Assets in a crisis or issue more currency. Instead, Digital Assets are an as-yet autonomous and largely unregulated worldwide system of currency.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(vi)</div>
              <div class="c-legal-description__content_list-data">Traders of Digital Assets put their trust in a digital, decentralised and partially anonymous system that relies on peer-to-peer networking and cryptography to maintain its integrity.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(vii)</div>
              <div class="c-legal-description__content_list-data">Due to the significant risks associated with Digital Assets, as well as the impact of technology and the international market, Sparrow does not warrant or guarantee that a particular Digital Asset will be available on the Website or any of the Services provided by Sparrow.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(viii)</div>
              <div class="c-legal-description__content_list-data">Sparrow may, at our sole and absolute discretion and in accordance with applicable law, decide to stop offering support for or conversions to particular Digital Assets, transfers of particular Digital Assets, or investment in the Dual Currency Product in relation to particular Digital Assets, on the Sparrow Platform at any time for any reason (the “Cease Trading Digital Asset”)(the “Cease Trading Decision”). Sparrow shall use reasonable endeavours to notify Users if Sparrow ceases to provide such support in relation to particular Digital Assets, and Users will be provided with at least seven (7) days’ notice prior to the Cease Trading Decision being made effective. If this occurs, Users will not be able to convert that Digital Asset into any other currency or token or transfer that Digital Asset to another User, however Users will be given the option to move the Cease Trading Digital Asset to another platform or third party. If Users fail move the Cease Trading Digital Asset to another platform or third party at the end of such seven (7) days’ notice period, Sparrow may, at our sole and absolute discretion, take such action as we consider necessary, including, but not limited to, forcing redemptions or liquidations by you of the Cease Trading Digital Asset.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(ix)</div>
              <div class="c-legal-description__content_list-data">Notwithstanding the clause above regarding the Cease Trading Decision, if Sparrow acting reasonably determines that an extreme market event is occurring in relation to a Digital Asset or multiple Digital Assets offered on the platform or if Sparrow is required or requested to comply with any applicable laws or regulations, then you agree that Sparrow may take such action as we consider necessary, and in accordance with applicable laws or regulations, to respond to this extreme market event by immediately suspending or terminating some of the Services, immediately removing or de-listing a Digital Asset from the platform, and forcing redemptions or liquidations by you of certain Digital Asset.</div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(x)</div>
              <div class="c-legal-description__content_list-data">Digital Assets are susceptible to irrational bubbles or loss of confidence, which could collapse demand relative to supply. For example, confidence might collapse in Digital Assets because of unexpected changes imposed by the software developers or others, a government crackdown, the creation of superior competing alternative currencies or tokens, or a deflationary or inflationary spiral. Confidence might also collapse because of technical problems, for example, if the anonymity of the system is compromised, if money is lost or stolen, or if hackers or governments are able to prevent any transactions from settling.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(xi)</div>
              <div class="c-legal-description__content_list-data">Sparrow shall not be liable for any Losses suffered by the User as a result of technical failures, hacks, government intervention or any other issue that negatively impacts the underlying blockchain or network of a Digital Asset which is supported on the Website or Sparrow Platform.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(xii)</div>
              <div class="c-legal-description__content_list-data">The User should carefully assess whether its financial situation and tolerance for risk is suitable for buying, selling, trading or holding Digital Assets or Sparrow Options.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(xiii)</div>
              <div class="c-legal-description__content_list-data">The User should carefully assess whether its financial situation and tolerance for risk is suitable for buying, selling, trading, transferring or holding Digital Assets or the investment in the Dual Currency Product.</div>
            </div>
            <div class="c-legal-description__content_list">
              <div class="c-legal-description__content_list-number">(xiv)</div>
              <div class="c-legal-description__content_list-data">Nothing on the Website is intended to be or should be taken as legal, financial, business or taxation advice. Users should seek their own legal, financial, business, tax and accounting advice in relation to using any Services.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">8.2</div>
      <div class="c-legal-description__content_list_data-text">Use of Website and Services
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.1</div>
          <div class="c-legal-description__content_list-data">The Website and Services are protected by copyright, intellectual property and other applicable laws. Any use of the Website, Platform or Services not specifically permitted under these Terms is strictly prohibited.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.2</div>
          <div class="c-legal-description__content_list-data">The Website and Services are operated and maintained by Sparrow for informational use and any information contained in the Website or as part of any Service should not be regarded as an offer or invitation to purchase or subscribe for any capital markets products, and no part of it shall form the basis of or be relied upon in connection with any contract, commitment or investment decision in relation thereto. Any User should obtain appropriate specific professional advice in connection therewith. In addition, the Website or any Service may not be used for the purpose of and does not constitute an offer or invitation to purchase or subscribe for any capital markets products in any jurisdiction or under any circumstances in which such offer or invitation is unlawful or unauthorised or to any person to whom it is unlawful to make such offer or invitation.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.3</div>
          <div class="c-legal-description__content_list-data">The information contained in the Website or Services may not be taken away, reproduced or redistributed to any other person. Information contained in the Website or Services is intended solely for the User’s personal reference and is strictly confidential. The User shall not modify the material on the Website or the Services in any manner or form, or to use modified versions of the Website or Services, or any materials on the Website or Services, including without limitation for the purpose of obtaining unauthorised access to the Website or Services.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.4</div>
          <div class="c-legal-description__content_list-data">The User shall not access the Services by any means other than through the Website.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.5</div>
          <div class="c-legal-description__content_list-data">The trademarks, service marks, product names, company names and logos used on the Website are either owned by Sparrow or are licenced or used with permission from a third party owner. Any third party trademark, service mark, product name, company or logo remains the property of the respective owner.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.6</div>
          <div class="c-legal-description__content_list-data">The Website may contain forward-looking statements that involve known and unknown risks, uncertainties and other factors which may cause the actual results, performance or achievements to be materially different from any future results, performance or achievements expected, expressed or implied by these forward-looking statements.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.7</div>
          <div class="c-legal-description__content_list-data">Given the risks and uncertainties that may cause the actual future results or performance of the investment in the Dual Currency Product to be materially different from that expected, expressed or implied by these forward-looking statements, undue reliance must not be placed on these statements. Sparrow does not represent or warrant that such actual future results or performance will be as discussed in this Website as such actual results may differ materially from those anticipated in these forward-looking statements. Sparrow disclaims any responsibility to update any of these forward-looking statements or publicly announce any revisions to these forward-looking statements to reflect future developments, events or circumstances.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.8</div>
          <div class="c-legal-description__content_list-data">The User understands and acknowledges that it may suffer Losses in conducting trading activities on the Sparrow Platform, and that Sparrow does not warrant or guarantee that the value of the Sparrow Dollars, the Dual Currency Product or any Digital Asset will appreciate, remain stable or depreciate. Sparrow further does not represent and does not guarantee that the User will profit from the buying, selling, trading, transferring, holding or otherwise of Sparrow Dollars, the Dual Currency Product or any Digital Asset. </div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.9</div>
          <div class="c-legal-description__content_list-data">The information contained in the Website or Services has not been independently verified. No representation or warranty, express or implied, is made as to, and no reliance should be placed on the fairness, accuracy, correctness, completeness, originality, timeliness, reasonableness, non-infringement, suitability, satisfactory quality, merchantability or fitness for any particular purpose of, the information or opinions contained in the Website or Services. It is not the intention of Sparrow to provide, and you may not rely on these materials as providing a complete or comprehensive analysis of the Services. The information and opinions contained in the Website or Services are subject to change without notice. The User agrees that the Services may be dependent on the pricing or information provided by third parties or third party websites and that Sparrow shall not have any liability, contingent or otherwise, to the User, for the correctness, quality, accuracy, security, completeness, reliability, performance, timeliness, pricing or continued availability of the Services on the Sparrow Platform and/or the Website, regardless of cause. The User further agrees that Sparrow shall not be liable or responsible in any way for the User’s use of the internet to connect to the Sparrow Platform and/or the Website, or any Force Majeure. 
          </div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.10</div>
          <div class="c-legal-description__content_list-data">Sparrow does not grant any representation or warranty, expressed or implied, as to the accessibility and quality of the Sparrow Platform and Website. There are situations when the Sparrow Platform and/or the Website will not be accessible, including but not limited to maintenance and circumstances outside the control of Sparrow such as net access failure, theft and blockchain mining attacks. As a result of the inherent deficiency in electronic distribution, there are also situations where there may be errors, delays, omissions, interruption, breach of security, corruption or unavailability of access in connection with or inaccuracies with the Sparrow Platform and/or the Website. The User agrees that Sparrow shall in no way be responsible for any erroneous order and/or transaction, or failure or any computer hardware or software used by Sparrow or a service provider of Sparrow, or any telecommunications devices used by Sparrow or a service provider of Sparrow or stoppage of service, which prevents Sparrow from fulfilling its obligations under this Agreement, provided that Sparrow shall use commercially reasonable efforts to prevent or limit such events. The User also agrees that Sparrow does not guarantee the continuous availability of the Website or any of the Services, which has the functionality as available during the User’s use, and that Sparrow shall not be responsible for any errors, delays, communication failures or other malfunctions of the Sparrow Platform and any Losses the User may suffer due to the inaccessibility of the Sparrow Platform.
          </div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.11</div>
          <div class="c-legal-description__content_list-data">Without limiting the foregoing, Sparrow does not warrant that the Sparrow Platform, Website, functions contained in or access to the Website or other content will be timely, uninterrupted or error-free without omission, that defects will be corrected, or that the Website or its contents are free from IT Malfunction or free of infection by computer viruses, computer worms, Trojan horses, spyware and/or other harmful or corrupt code, programme, macro and such other unauthorised or malicious software, or that the download, installation or use of any software or content of the Website in or with any computer will not affect the functionality or performance of the computer. The User (and not Sparrow) shall assume the entire cost of all necessary servicing, repair, or correction, including any defect, problem or damage in the computer. The User agrees not to hold Sparrow liable for the loss of any of the contents in its computer or for any loss that the User suffers that is due to any circumstances beyond the control of Sparrow.
          </div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.12</div>
          <div class="c-legal-description__content_list-data">Sparrow shall not be liable to any User or be deemed in breach of this Agreement by reason of any delay in performing, or any failure to perform, its obligations, if the delay or failure was due to a Force Majeure Event.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.13</div>
          <div class="c-legal-description__content_list-data">The delivery of the Website in certain jurisdictions may be restricted or prohibited by law in such jurisdictions. Persons who access the Website or Users must familiarise themselves, and observe and comply, with any such prohibitions and/or restrictions.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.14</div>
          <div class="c-legal-description__content_list-data">To the fullest extent permitted by law, none of Sparrow nor any of its Representatives shall be liable (in negligence or otherwise) for any Loss howsoever arising from any use of the Website and/or the Services. The User shall hold harmless and indemnify (and keep indemnified) Sparrow in relation to any Losses suffered or incurred by Sparrow in relation to any breach of the Agreement by such User.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.15</div>
          <div class="c-legal-description__content_list-data">The User should seek professional advice if in doubt to ensure compliance with all applicable laws and regulations.</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">8.2.16</div>
          <div class="c-legal-description__content_list-data">The User acknowledges and agrees that all information provided to Sparrow may be shared between Sparrow Holdings Pte. Ltd. and its subsidiaries, affiliates, associated companies and jointly controlled entities.</div>
        </div>
      </div>
    </div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      9.	<span class="c-legal-description__title_underline">LICENCE</span>
    </p>
    <div class="c-legal-description__content_list_data  c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">9.1</div>
      <div class="c-legal-description__content_list_data-text">Provided that the User complies with these Terms, Sparrow shall grant the User a limited, royalty-free, personal, revocable and non-transferable licence to access the Website and Services for his own personal, informational, non-commercial use. Sparrow reserves all rights, title and interest not expressly granted under this licence to the fullest extent possible under law.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      10.	<span class="c-legal-description__title_underline">PRIVACY AND DATA PROTECTION</span>
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">10.1</div>
      <div class="c-legal-description__content_list_data-text">Sparrow shall manage and protect personal data in accordance with the Personal Data Protection Act 2012 (No. 26 of 2012) and its Privacy and Data Protection Policy.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      11.	<span class="c-legal-description__title_underline">LIABILITY AND INDEMNITY</span>
    </p>
    <div class="c-legal-description__content_list_data">
      <div class="c-legal-description__content_list_data-number">11.1</div>
      <div class="c-legal-description__content_list_data-text">Sparrow, or any of its Representatives, shall not be liable for any Losses, arising out of or in any way connected with:-
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.1</div>
          <div class="c-legal-description__content_list-data">the use or performance of the Website or the Services thereon;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.2</div>
          <div class="c-legal-description__content_list-data">trading activities conducted on the Sparrow Platform;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.3</div>
          <div class="c-legal-description__content_list-data">the withdrawal or settlement process;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.4</div>
          <div class="c-legal-description__content_list-data">the delay or inability to use the Website or the Services thereon; </div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.5</div>
          <div class="c-legal-description__content_list-data">any Force Majeure Event;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.6</div>
          <div class="c-legal-description__content_list-data">the provision of or failure to provide the Website or the Services thereon;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.7</div>
          <div class="c-legal-description__content_list-data">any information, data, software, products, services and related graphics obtained through the Website or the Services thereon;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.8</div>
          <div class="c-legal-description__content_list-data">any reliance on any statement, opinion, representation or information on the Website or the Services thereon; or</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">11.1.9</div>
          <div class="c-legal-description__content_list-data">otherwise arising out of the use of the Website or the Services thereon,</div>
        </div>
        <div class="c-legal-description__content_list c-legal-description__content-term-of-use-margin-top-20">
          <!-- <div class="c-legal-description__content_list-number">10.1.9</div> -->
          <div class="c-legal-description__content_list-data">whether based on contract, tort, strict liability or otherwise, except where such Losses arise from fraud by Sparrow.</div>
        </div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">11.2</div>
      <div class="c-legal-description__content_list_data-text">In the event that Sparrow is liable for damages, the User agrees that Sparrow’s aggregate liability to the User for any and all damages, Losses and causes of action (whether in contract, tort including, without limitation, negligence, or otherwise) in relation to his use of the Services shall not exceed the total amount of fees and charges paid by the User to Sparrow in the 12-month period immediately preceding the time such liability arose.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">11.3</div>
      <div class="c-legal-description__content_list_data-text">Without prejudice to the other provisions herein, the User irrevocably and unconditionally agrees to hold harmless and indemnify (and keep indemnified) Sparrow and its Representatives (collectively the “Indemnified Parties”) on demand against any and all Losses which the Indemnified Parties may sustain, incur, suffer or pay arising out of, in connection with or pursuant to the access to and/or the use of the Website or its Services by the User, whether or not such access or use was authorised or whether it was due to any act or omission on its part, the breach of this Agreement by the User, the violation by the User of any rights of another person or Entity or the breach by the User of any statutory requirement, duty or law.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      12.	<span class="c-legal-description__title_underline">ANTI-MONEY LAUNDERING AND COUNTERING THE FINANCING OF TERRORISM</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">12.1</div>
      <div class="c-legal-description__content_list_data-text">The User represents that the Sparrow Dollars was not and is not directly or indirectly derived from activities that may contravene laws and regulations in Singapore as well as international laws and regulations, including anti-money laundering and countering the financing of terrorism laws and regulations.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">12.2</div>
      <div class="c-legal-description__content_list_data-text">Accordingly, the User represents and warrants that, to the best of its knowledge, none of:
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">12.2.1</div>
          <div class="c-legal-description__content_list-data">the User;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">12.2.2</div>
          <div class="c-legal-description__content_list-data">any person controlling or controlled by the User;</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">12.2.3</div>
          <div class="c-legal-description__content_list-data">if the User is a privately held Entity, any person having a beneficial interest in the User; or</div>
        </div>
        <div class="c-legal-description__content_list">
          <div class="c-legal-description__content_list-number">12.2.4</div>
          <div class="c-legal-description__content_list-data">any person for whom the User is acting as agent or nominee in connection with this trading,</div>
        </div>
        <div class="c-legal-description__content_list c-legal-description__content-term-of-use-margin-top-20">
          <!-- <div class="c-legal-description__content_list-number">11.2.4</div> -->
          <div class="c-legal-description__content_list-data">is a country, territory, individual or Entity named on a restricted list by the relevant regulatory authority in Singapore or such other jurisdiction.</div>
        </div>
        <div class="c-legal-description__content_list c-legal-description__content-term-of-use-margin-top-20">
          <!-- <div class="c-legal-description__content_list-number">11.2.4</div> -->
          <div class="c-legal-description__content_list-data">Please be advised that Sparrow may not accept any trading activities from a prospective User if it cannot make the representations set forth in clauses 12.1 and 12.2 above. In addition, if the User cannot make these representations, Sparrow, at their sole and absolute discretion, may prohibit, terminate or suspend the trading activities and account of such User.</div>
        </div>
      </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">12.3</div>
      <div class="c-legal-description__content_list_data-text">The User agrees promptly to notify Sparrow should the User become aware of any change in the information set forth in the representations set out in clauses 12.1 and 12.2 above. The User is advised that, by law, Sparrow may be obligated to "freeze the account" of such User, by prohibiting any trading activities from the User in compliance with governmental regulations, and Sparrow and/or its Representatives may also be required to report such action and to disclose the User’s identity to the relevant authorities. In particular, the User agrees that Sparrow shall be entitled to (i) exercise its rights under Clauses 5 and 6 of the Terms; and (ii) report instances of fraudulent or criminal behaviour or suspicious transactions to the relevant authorities. </div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">12.4</div>
      <div class="c-legal-description__content_list_data-text">The User hereby agrees to promptly provide any additional documentation Sparrow and/or its Representatives may request in the future to the extent that Sparrow determines necessary in order to comply with applicable anti-money laundering laws or policies or any other applicable law and the User acknowledges and consents to the disclosure by Sparrow and/or its Representatives upon request in connection with money laundering and similar matters in Singapore and such other jurisdictions.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">12.5</div>
      <div class="c-legal-description__content_list_data-text">The User hereby agrees that Sparrow and/or its Representatives may disclose to each other, to any other service provider engaged by Sparrow, or to any regulatory body in any applicable jurisdiction any information concerning them and their associates provided by them to Sparrow and/or its Representatives and any such disclosure shall not be treated as a breach of any restriction upon the disclosure of information imposed on such person by law or otherwise.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      13.	<span class="c-legal-description__title_underline">THIRD PARTY WEBSITES</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">13.1</div>
      <div class="c-legal-description__content_list_data-text">The Website may contain information concerning third parties, third party products and services or links to third-party websites or applications. Sparrow does not monitor, control or endorse these third parties, their products, services, websites or applications. Any links to other websites are provided as a convenience to the User, and does not imply Sparrow’s endorsement of the linked website or association with their operators.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">13.2</div>
      <div class="c-legal-description__content_list_data-text">Sparrow makes no representation as to the quality, suitability, functionality, accuracy or legality of the materials on third party websites that are linked to, or to any goods and services available for such websites.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">13.3</div>
      <div class="c-legal-description__content_list_data-text">To the maximum extent permitted by law, Sparrow is not responsible and disclaim all liability for (a) such third party products, services, websites or applications; (b) for any act or omission of these third parties, or (c) any dealings between you and these third parties, whether or not such dealings have been performed or facilitated through the Website, arising out of or in relation to anything done or omitted to be done in relation to these materials, or any delay, inaccuracies or omissions in the materials or any interruption to the supply of materials.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      14.	<span class="c-legal-description__title_underline">ENTIRE AGREEMENT</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">14.1</div>
      <div class="c-legal-description__content_list_data-text">The Agreement embodies the entire agreement between the Parties with respect to the subject matter of the Agreement and supersedes any previous agreements relating to the subject matter.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">14.2</div>
      <div class="c-legal-description__content_list_data-text">Except to the extent that they have been performed and except where the Agreement provides otherwise, the obligations contained in the Agreement remain in force after completion of the matters set out therein.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">14.3</div>
      <div class="c-legal-description__content_list_data-text">The Agreement shall be binding on and shall endure for the benefit of each of the Parties’ successors in title or legal personal representatives.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      15.	<span class="c-legal-description__title_underline">SEVERANCE</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">15.1</div>
      <div class="c-legal-description__content_list_data-text">If at any time any provision of the Agreement is or becomes illegal, invalid or unenforceable under the laws of any jurisdiction, that shall not affect: (a) the legality, validity or enforceability in that jurisdiction of any other provision of the Agreement; or (b) the legality, validity or enforceability under the laws of any other jurisdiction of that or another provision of the Agreement.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      16.	<span class="c-legal-description__title_underline">AMENDMENT</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">16.1</div>
      <div class="c-legal-description__content_list_data-text">Sparrow may, in its sole and absolute discretion, supplement, vary or amend the Agreement from time to time immediately upon written notification to the User. Changes to the Agreement will be posted on the Website and/or specifically notified by Sparrow. It is the responsibility of the User to review the Agreement regularly upon each access or use to ensure that it are aware of any changes made by Sparrow. The continued access or use of the Website and/or its Services by the User after such changes are posted whether or not reviewed by User, constitutes its agreement to be legally bound by the Agreement so amended and for the revised Agreement to apply to all current and past usage by the User of this Website. In the event that the User does not agree to any of the changes, Sparrow is not obliged to continue providing the User with any Service, and the User must stop using the Website and/or the Services provided thereon.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      17. <span class="c-legal-description__title_underline">ASSIGNMENT</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">17.1</div>
      <div class="c-legal-description__content_list_data-text">You may not transfer or assign any rights or obligations you may have under the Agreement unless Sparrow has provided written consent to such assignment. Where it is in the Sparrow’s interest to do so, Sparrow may, from time to time immediately upon written notification to the User, assign or transfer to any third party (including, without limitation, to any of its affiliates) any of the Sparrow’s rights and obligations under the Agreement without your consent and you irrevocably consent to any such assignment or transfer by Sparrow. </div>
    </div>


    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      18.	<span class="c-legal-description__title_underline">CONTRACTS (RIGHTS OF THIRD PARTIES) ACT</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">18.1</div>
      <div class="c-legal-description__content_list_data-text">The Contracts (Rights of Third Parties) Act 2001 of Singapore shall not under any circumstances apply to the Agreement and any person who is not a party to the Agreement (whether or not such person shall be named, referred to, or otherwise identified, or shall form part of a class of persons so named, referred to, or identified, in the Agreement) shall have no right whatsoever under the Contracts (Rights of Third Parties) Act 2001 of Singapore to enforce the Agreement or any of the terms.</div>
    </div>
    <p class="c-legal-description__title-term-of-use c-legal-description__content-term-of-use-margin-bottom-20 c-legal-description__title_bold c-legal-description__content-term-of-use-margin-top-40">
      19.	<span class="c-legal-description__title_underline">GOVERNING LAW AND JURISDICTION</span>
    </p>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">19.1</div>
      <div class="c-legal-description__content_list_data-text">The Agreement shall be governed by and construed in accordance with the laws of Singapore.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">19.2</div>
      <div class="c-legal-description__content_list_data-text">All disputes, controversies or differences (<span class="c-legal-description__content_bold">"Dispute"</span>) arising out of or in connection with the Agreement, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration conducted in English in Singapore by a single arbitrator in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (the <span class="c-legal-description__content_bold">"SIAC"</span>) for the time being in force which rules shall be deemed to be incorporated by reference in this clause 19.2.</div>
    </div>
    <div class="c-legal-description__content_list_data c-legal-description__content-term-of-use-margin-top-20">
      <div class="c-legal-description__content_list_data-number">19.3</div>
      <div class="c-legal-description__content_list_data-text">The Parties further agree that following the commencement of arbitration pursuant to clause 19.2, they will attempt in good faith to resolve the Dispute through mediation at the Singapore International Mediation Centre (the <span class="c-legal-description__content_bold">"SIMC"</span>), in accordance with the SIAC-SIMC Arb-Med-Arb Protocol for the time being in force. Any settlement reached in the course of the mediation shall be referred to the arbitral tribunal appointed pursuant to clause 19.2 and the terms of such settlement may be embodied in a consent award on agreed terms. </div>
    </div>
    <div class="c-legal-description__content-term-of-use-margin-top-20 c-legal-description__content-term-of-use-margin-bottom-70" :class="{'c-legal-description__content-term-of-use-margin-bottom-50': styleModal}">
      <div class="c-legal-description__content-version">
        GUA Version 6.0
      </div>
      <div class="c-legal-description__content-version">
        Last modified on 15 November 2023
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserAgreement',
  props: {
    styleModal: Boolean,
  },
  data() {
    return {

    }
  },
}
</script>
