import store from '../store'
const { CONTACT_US_URL, HELP_CENTER_URL } = require('./../../.env.json')
export default {
  data: function () {
    return {
      configJson: require('../../config.json'), //get config in root
    }
  },
  methods: {
    mailTo(email) {
      window.open('mailto:' + email);
    },
    openLink(url) {
      if (url.includes("http")) {
        window.open(url)
      } else {
        window.location.href = url
      }
    },
    redirectUrl(url) {
      window.location.href = url
    },
    redirectMobileUrl(url) {
      window.location.href = this.configJson.mobile_url + url
    },
    noReplyURL: function () {
      return atob(this.configJson.no_reply_url)
    },
    createApiUrlChestNut: function (url) {
      return this.configJson.api_chestnut + url
    },
    createApiUrlPricing: function (url) {
      return this.configJson.api_pricing + url
    },
    contactUsUrl: function () {
      return CONTACT_US_URL;
    },
    helpCenterUrl: function () {
      return HELP_CENTER_URL;
    },
    updateTitleMeta: function (title) {
      document.title = title
      document.querySelector('meta[property="og:title"]').setAttribute("content", title);
    },
    updateDescriptionMeta: function (desc) {
      document.querySelector('meta[name="description"]').setAttribute("content", desc);
      document.querySelector('meta[property="og:description"]').setAttribute("content", desc);
    },
    updateKeywordMeta: function (keyword) {
      document.querySelector('meta[name="keywords"]').setAttribute("content", keyword);
    },
    routerRedirect: function (route) {
      this.$router.push(route).catch(() => { });
    },
    routerRedirectBlank: function (route) {
      let routeData = this.$router.resolve({ path: route });
      window.open(routeData.href, '_blank');
    },
    getDictionary: function () {
      var request = new XMLHttpRequest();
      request.open("GET", "/file/translation_dashboard.xml", false);
      request.send();
      var xml = request.responseXML;
      var data = this.xmlToJson(xml);
      return data;
    },
    xmlToJson: function (xml) {
      var obj = {};

      if (xml.nodeType == 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
          obj["@attributes"] = {};
          for (var j = 0; j < xml.attributes.length; j++) {
            var attribute = xml.attributes.item(j);
            obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType == 3) { // text
        obj = xml.nodeValue;
      }

      // do children
      if (xml.hasChildNodes()) {
        for (var i = 0; i < xml.childNodes.length; i++) {
          var item = xml.childNodes.item(i);
          var nodeName = item.nodeName;
          if (typeof (obj[nodeName]) == "undefined") {
            obj[nodeName] = this.xmlToJson(item);
          } else {
            if (typeof (obj[nodeName].push) == "undefined") {
              var old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(this.xmlToJson(item));
          }
        }
      }
      return obj;
    },
    translate: function (value, array_var) { // array var for translate {{testing}}

      var data = '';
      var lang = store.getters.getLang;
      var dictionary = store.getters.getDictionary;
      var lang_param = '';

      if (lang == 'zh-CN') {
        lang_param = 'SimplifiedChinese';
      } else if (lang == 'en') {
        lang_param = 'English';
      } else if (lang == 'ko') {
        lang_param = 'Korean';
      }

      if (!dictionary.data) {
        return value
      }

      Object.keys(dictionary.data).forEach(function (item) {
        if (item != "@attributes" && item != "#text") {
          dictionary.data[item].record.forEach(function (detail) {
            if (detail.code['#text'] == value) {
              let translation = detail[lang_param]
              if (!translation) {
                translation = detail.English
              }
              data = translation['#text']

              if (array_var) {
                Object.keys(array_var).forEach(function (key) {
                  data = data.replace("{{" + key + "}}", array_var[key])
                })
              }
              return data;
            }
          });
        }
      });
      return data
    },
    showNotifSuccess(string, title) {
      this.$notify({
        title: (title ? title : 'Success'),
        message: string,
        type: 'success',
        offset: 105,
      });
    },
    showNotifError(string, title) {
      this.$notify.error({
        title: (title ? title : 'Error'),
        message: string,
        type: 'error',
        offset: 105
      });
    },
  }
}
