<template>
  <div class="o-home">
    <Banner></Banner>
    <HomeDescription></HomeDescription>
    <Featured></Featured>
    <AsSeenOn></AsSeenOn>
    <WhySparrow></WhySparrow>
    <GetStarted></GetStarted>
    <Footer></Footer>
  </div>
</template>

<script>
import AsSeenOn from '@/components/AsSeenOn.vue'
// import SparrowBlog from '@/components/SparrowBlog.vue'
import WhySparrow from '@/components/WhySparrow.vue'
import Banner from '@/components/Banner.vue'
import Featured from '@/components/Featured.vue'
import HomeDescription from '@/components/HomeDescription.vue'
import GetStarted from '@/components/GetStarted.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    AsSeenOn,
    WhySparrow,
    Banner,
    // SparrowBlog,
    Featured,
    HomeDescription,
    GetStarted,
    Footer,
  },
  data() {
    return {
    }
  },
  mounted: function () {
    // this.$store.commit('doLogin',{token:''})
    // this.$eventHub.$emit('show-alert', true, {title: 'Oops, something went wrong.', body: 'Please try again.'}, false)
    // this.$eventHub.$emit('show-alert', true, {title: 'Thank you for your query and interest in Sparrow`s digital asset wealth management solutions.', body: 'Our team will be in touch with you shortly.'}, true)
    // setTimeout(this.checkSession, 100)
    this.updateTitleMeta('Sparrow | Your Trusted Digital Asset Solutions Specialist')
    this.updateDescriptionMeta('Invest cryptocurrencies in Singapore with Sparrow’s digital assets trading platform designed for individuals or customized with bespoke digital asset solutions.')
    this.updateKeywordMeta('Sparrow, Sparrow Exchange, wealth management in Singapore, investment in Singapore, trading platform, cryptocurrency in Singapore')
  },
  methods: {
    checkSession: function () {
      if (this.token) {
        window.location = 'dashboard/#/checker'
      }
    },
  },
  computed: {
    token: function () {
      return this.$store.getters.token;
    },
  }
}
</script>
