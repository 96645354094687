import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', searchable: true, component: Home },
  { path: '/signin', name: 'Signin', searchable: true, component: () => import('../views/Signin.vue') },
  { path: '/signin-bypass', name: 'SigninBypass', searchable: false, component: () => import('../views/SigninBypass.vue') },
  { path: '/signup', name: 'Signup', searchable: true, component: () => import('../views/Signup.vue') },
  { path: '/signup-bypass', name: 'SignupBypass', searchable: false, component: () => import('../views/SignupBypass.vue') },
  { path: '/about/who-we-are', name: 'Who We Are', searchable: true, component: () => import('../views/about/WhoWeAre.vue') },
  { path: '/about/vision', name: 'Vision & Mission', searchable: true, component: () => import('../views/about/VisionAndMission.vue') },
  { path: '/about/core-values', name: 'Core Values', searchable: true, component: () => import('../views/about/CoreValues.vue') },

  { path: '/about/partners', name: 'Our Partners', searchable: true, component: () => import('../views/about/OurPartners.vue') },
  { path: '/about/milestone', name: 'Milestones & Achievements', searchable: true, component: () => import('../views/about/MilestoneAndAchievement.vue') },
  { path: '/about/why-sparrow', name: 'Why Sparrow', searchable: true, component: () => import('../views/about/WhySparrow.vue') },
  // {path: '/institutional/institution', name: 'Institutions & Wealth managers', searchable: true, component: () => import('../views/institutional/Institution.vue')},
  { path: '/institutional/wealth', name: 'Bespoke Wealth Management', searchable: true, component: () => import('../views/institutional/Wealth.vue') },
  { path: '/institutional/getting-started', name: 'Getting Started (Institutional)', searchable: true, component: () => import('../views/institutional/GettingStarted.vue') },
  { path: '/consumer/individual', name: 'Personal', searchable: true, component: () => import('../views/consumer/Individual.vue') },
  { path: '/consumer/getting-started', name: 'Getting Started (Personal)', searchable: true, component: () => import('../views/consumer/GettingStarted.vue') },
  { path: '/library/sparrow-blog', name: 'Sparrow Blog', searchable: true, component: () => import('../views/library/SparrowBlog.vue') },
  { path: '/library/news-media-room', name: 'News & Media Room', searchable: true, component: () => import('../views/library/NewsMediaRoom.vue') },
  { path: '/library/announcements', name: 'Announcements', searchable: true, component: () => import('../views/library/announcements/Announcements.vue') },
  { path: '/library/announcements-detail', name: 'Announcements Detail Page', searchable: true, component: () => import('../views/library/announcements/AnnouncementsDetail.vue') },
  { path: '/legal/regulation', name: 'Regulation', searchable: true, component: () => import('../views/legal/Regulation.vue') },
  { path: '/legal/user-protection-guidance', name: 'User Protection Guidance', searchable: true, component: () => import('../views/legal/UserProtectionGuidance.vue') },
  { path: '/legal/user-agreement', name: 'User Agreement', searchable: true, component: () => import('../views/legal/UserAgreement.vue') },
  { path: '/legal/privacy-policies', name: 'Privacy Policies', searchable: true, component: () => import('../views/legal/PrivacyPolicies.vue') },
  // {path: '/legal/security', name: 'Security', searchable: true, component: () => import('../views/legal/Security.vue')},
  { path: '/legal/technology', name: 'Technology', searchable: true, component: () => import('../views/legal/Technology.vue') },
  { path: '/legal/disclaimers-and-risk-warning', name: 'Disclaimers & Risk Warning', searchable: true, component: () => import('../views/legal/DisclaimersRiskWarning.vue') },

  { path: '/contact-us/reach-out', name: 'Reach Out to Sparrow', searchable: true, component: () => import('../views/contactus/ReachOutSparrow.vue') },
  { path: '/support/sparrowcares', name: 'Sparrow Cares', searchable: true, component: () => import('../views/support/TroubleshootCenter.vue') },
  { path: '/support/faq', name: 'FAQ', searchable: true, component: () => import('../views/support/Faq.vue') },
  { path: '/notif-changepwd', name: 'Notif Change Password', searchable: false, component: () => import('../components/NotifChangepwd.vue') },
  { path: '/device-auth', name: 'Device Auth', searchable: false, component: () => import('../views/signin/DeviceAuth.vue') },
  { path: '/verify', name: 'Verify', searchable: false, component: () => import('../views/signup/Verify.vue') },
  { path: '/reset-password', name: 'Reset Password', searchable: false, component: () => import('../views/resetpassword/ResetPassword.vue') },
  { path: '/emailsent-apikeyconfirmed', name: 'New API Key Created', searchable: false, component: () => import('../views/emailsent/ApiKeyConfirmed.vue') },
  { path: '/emailsent-apikeyexpired', name: 'Confirmation Email Expired', searchable: false, component: () => import('../views/emailsent/ApiKeyExpired.vue') },
  { path: '/emailsent-apikeyrejected', name: 'API Key Cancelled', searchable: false, component: () => import('../views/emailsent/ApiKeyRejected.vue') },
  { path: '/emailsent-apikeyrequest', name: 'New API Key Request', searchable: false, component: () => import('../views/emailsent/ApiKeyRequest.vue') },
  { path: '/emailsent-forgotpwd', name: 'Email Sent', searchable: false, component: () => import('../views/emailsent/ForgotPwd.vue') },
  { path: '/emailsent-verification', name: 'Verification Email Sent', searchable: false, component: () => import('../views/emailsent/Verification.vue') },
  { path: '/emailsent-withdraw', name: 'Withdraw Email Sent', searchable: false, component: () => import('../views/emailsent/Withdraw.vue') },
  { path: '/emailsent-withdrawcancelled', name: 'Pending Withdrawal Cancelled', searchable: false, component: () => import('../views/emailsent/WithdrawCancelled.vue') },
  { path: '/emailsent-withdrawexpired', name: 'Withdraw Confirmation Email Expired', searchable: false, component: () => import('../views/emailsent/WithdrawExpired.vue') },
  { path: '/emailsent-withdrawsuccess', name: 'Withdrawal Confirmation Successful', searchable: false, component: () => import('../views/emailsent/WithdrawSuccess.vue') },
  { path: '/whitelisted-address-email-verify', name: 'Whitelist Email Withdrawal', searchable: false, component: () => import('../views/WhitelistEmailWithdrawal.vue') },
  { path: '/whitelisted-deposit-address-email-verify', name: 'Whitelist Email Deposit', searchable: false, component: () => import('../views/WhitelistEmailDeposit.vue') },
  { path: '/whitelist-success', name: 'Whitelist Success', searchable: false, component: () => import('../components/WhiteListSuccess.vue') },
  { path: '/whitelist-expired', name: 'Whitelist Expired', searchable: false, component: () => import('../components/WhiteListExpired.vue'), props: true },
  { path: '/otp', name: 'Signin Twofa', searchable: false, component: () => import('../views/signin/SigninOtp.vue'), props: true },
  { path: '/maintenance', name: 'Maintenance', searchable: false, component: () => import('../views/Maintenance.vue') },
  { path: '/success-process', name: 'SuccessProcess', searchable: false, component: () => import('../views/SuccessProcess.vue') },
  { path: '/resend-verification', name: 'ResendVerification', searchable: false, component: () => import('../views/resendverification/ResendVerification.vue') },
  { path: '/unrecognized', name: 'Unrecognized', searchable: false, component: () => import('../views/Unrecognized.vue') },
  { path: '*', name: 'Page 404', searchable: false, component: () => import('../components/404.vue') },
  { path: '/forgot-password', name: 'Forgot Password', searchable: false, component: () => import('../components/ForgotPwd.vue') },
  { path: '/verification-process', name: 'Verification Process', searchable: false, component: () => import('../views/VerificationProcess.vue'), props: true },
  { path: '/registration-process', name: 'Registration Process', searchable: false, component: () => import('../views/RegistrationProcess.vue') },
  { path: '/jumio-success', name: 'JumioSuccess', searchable: false, component: () => import('../views/jumio/Success.vue') },
  { path: '/jumio-failed', name: 'JumioFailed', searchable: false, component: () => import('../views/jumio/Failed.vue') },
  { path: '/people-at-sparrow/join-sparrow', name: 'Join Sparrow', searchable: true, component: () => import('../views/peopleatsparrow/JoinSparrow.vue') },
  { path: '/people-at-sparrow/team', name: 'Meet The Team', searchable: true, component: () => import('../views/peopleatsparrow/MeetTheTeam.vue') },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
