<template>
  <div class="c-featured">
    <div class="c-featured__container">
      <div class="c-featured__item" v-for="(dt, index) in featuredData" :key="index">
        <div class="c-featured__item-box" @click="openLink(dt.link)">
          <img :src="dt.img" alt="featured-thumbnail" class="c-featured__item-image">
          <div class="c-featured__overlay">
            <p class="c-featured__overlay-text-small">{{ dt.title }}</p>
            <h3 class="c-featured__overlay-text-big">{{ dt.desc }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      featuredData: []
    }
  },
  mounted() {
    this.getFeaturedData()
  },
  methods: {
    getFeaturedData() {
      let hardCodeFeatured = [
        {
          title: 'Start trading on our easy-to-use',
          desc: 'trading platform',
          img: require('@/assets/images/featured/featured-options trading platform.jpg'),
          link: '/consumer/individual'
        },
        {
          title: 'Learn more about the',
          desc: 'Sparrow business',
          img: require('@/assets/images/featured/featured-Sparrow business.jpg'),
          link: '/about/who-we-are'
        },
        {
          title: 'Explore digital asset products tailored to cater to',
          desc: 'institutions and accredited clients',
          img: require('@/assets/images/featured/featured-institutions and family offices.jpg'),
          link: '/institutional/wealth'
        },
        {
          title: 'Sparrow celebrates',
          desc: 'major milestones and achievements.',
          img: require('@/assets/images/featured/featured-20 established digital asset investors.jpg'),
          link: '/about/milestone'
        },
        {
          title: "Learn about Sparrow's",
          desc: 'legal and compliance framework',
          img: require('@/assets/images/featured/featured-legal and compliance framework.jpg'),
          link: '/legal/regulation'
        },
        {
          title: 'Meet',
          desc: "Sparrow's Team of Experts",
          img: require("@/assets/images/featured/Meet Sparrow's Team of Experts-02.jpg"),
          link: '/people-at-sparrow/team'
        },
      ]

      this.featuredData = hardCodeFeatured
    }
  }
}
</script>
