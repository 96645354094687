<template>
  <div class="c-riskwarning">
    <div class="c-riskwarning__mask"></div>
    <div class="c-riskwarning__body">
      <div class="c-riskwarning__body-modal c-riskwarning__body-modal_new">
        <h3 class="c-riskwarning__body-modal-title">Risk Warning on Digital Payment Token Services</h3>
        <div class="c-riskwarning__body-modal-content c-riskwarning__body-modal-content_new">
          <p>The Monetary Authority of Singapore (MAS) requires us to provide this risk warning to you as a customer of a Digital Payment Token (DPT) service provider. Before you pay your DPT service provider any money or DPT, you should be aware of the following.</p>
          <div class="c-riskwarning__body-modal-content-list">
            <p class="c-riskwarning__body-modal-content-list-number c-riskwarning__body-modal-content-list-number_nopadding">1.</p>
            <p class="c-riskwarning__body-modal-content-list-detail">Your DPT service provider is licensed by MAS to provide DPT services. Please note that this does not mean you will be able to recover all the money or DPTs you paid to your DPT service provider if your DPT service provider’s business fails.</p>
          </div>
          <div class="c-riskwarning__body-modal-content-list">
            <p class="c-riskwarning__body-modal-content-list-number c-riskwarning__body-modal-content-list-number_nopadding">2.</p>
            <p class="c-riskwarning__body-modal-content-list-detail">You should not transact in the DPT if you are not familiar with this DPT. This includes how the DPT is created, and how the DPT you intend to transact is transferred or held by your DPT service provider.</p>
          </div>
          <div class="c-riskwarning__body-modal-content-list">
            <p class="c-riskwarning__body-modal-content-list-number c-riskwarning__body-modal-content-list-number_nopadding">3.</p>
            <p class="c-riskwarning__body-modal-content-list-detail">You should be aware that the value of DPTs may fluctuate greatly. You should buy DPTs only if you are prepared to accept the risk of losing all of the money you put into such tokens.</p>
          </div>
          <div class="c-riskwarning__body-modal-content-list">
            <p class="c-riskwarning__body-modal-content-list-number c-riskwarning__body-modal-content-list-number_nopadding">4.</p>
            <p class="c-riskwarning__body-modal-content-list-detail">You should be aware that your DPT service provider, as part of its licence to provide DPT services, may offer services related to DPTs which are promoted as having a stable value, commonly known as “stablecoin”.</p>
          </div>
          <div class="c-riskwarning__body-modal-content-source">
            <p class="c-riskwarning__body-modal-content-source-text"><span class="c-riskwarning__body-modal-content-source-text_bold">Source:</span> https://www.mas.gov.sg/-/media/MAS-Media-Library/regulation/notices/PSO/psn08-notice-on-disclosures-and-communications/Notice-PSN08-on-Disclosures-7-Mar-2022.pdf </p>
          </div>
          <div class="c-riskwarning__body-modal-content-disclaimer">
            <p class="c-riskwarning__body-modal-content-disclaimer-text c-riskwarning__body-modal-content-disclaimer-text_bold">Disclaimer:</p>
            <p class="c-riskwarning__body-modal-content-disclaimer-text">The information provided here is for information purposes only and is not to be construed as a recommendation or advice to any prospective investor in relation to any legal, tax, financial investment or any other matters. You should consult with an attorney or other professional advisors to determine what may be best for your individual needs.</p>
            <p class="c-riskwarning__body-modal-content-disclaimer-text">Sparrow Tech Private Limited (“Sparrow”) does not make any guarantee or other promise as to any results that may be obtained from using our content. In making any decisions regarding our content, prospective users should first consult his or her own financial advisor and rely on their own examination of the terms of the offering, including the merits and risks of investing in the relevant products.</p>
            <p class="c-riskwarning__body-modal-content-disclaimer-text">To the maximum extent permitted by law, Sparrow shall not be liable in the event of any information, commentary, analysis, opinions, advice and/or recommendations proving to be inaccurate, incomplete or unreliable, or result in any decisions regarding our content or other losses.</p>
            <p class="c-riskwarning__body-modal-content-disclaimer-text">Content contained on or made available through any of our communication channels is not intended to and does not constitute legal advice or investment advice and no attorney-client relationship is formed. Your use of the information on any of our communication channels is at your own risk.</p>
            <p class="c-riskwarning__body-modal-content-disclaimer-text">I hereby acknowledge that I have read and understand the contents of the Risk Warning and Disclaimer and I accept and agree with all the terms stated therein.</p>
          </div>
          <div class="c-riskwarning__body-modal-content-button-wrapper">
            <!-- <button class="c-riskwarning__body-modal-content-button c-riskwarning__body-modal-content-button_cancel" @click="cancelRisk">Cancel</button> -->
            <button class="c-riskwarning__body-modal-content-button c-riskwarning__body-modal-content-button_accept" @click="acceptRisk">Accept & Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    cancelRisk() {
      this.$eventHub.$emit('show-hide-risk', false);
    },
    acceptRisk() {
      this.$eventHub.$emit('show-hide-risk', false);
      // this.redirectUrl(this.configJson.desktop_url+"/signup")
    }
  }
}
</script>
