<template>
  <div class="c-menu">
    <div class="c-menu__container c-menu__container_desktop">
      <!-- <div class="c-menu__container" v-if="screenWidth > 0"> -->
      <a @click='routerRedirect("/about/who-we-are")'>
        <div class="c-menu__container-list" :class="{ ' c-menu__container-list_active': currentPage == 'about' }">About
        </div>
      </a>
      <a @click='routerRedirect("/institutional/wealth")'>
        <div class="c-menu__container-list" :class="{ ' c-menu__container-list_active': currentPage == 'consumer' }">
          Personal & Services</div>
      </a>
      <a @click='routerRedirect("/people-at-sparrow/team")'>
        <div class="c-menu__container-list"
          :class="{ ' c-menu__container-list_active': currentPage == 'peopleatsparrow' }">
          People at Sparrow</div>
      </a>
      <a @click='routerRedirect("/legal/disclaimers-and-risk-warning")'>
        <div class="c-menu__container-list" :class="{ ' c-menu__container-list_active': currentPage == 'legal' }">Legal &
          Compliance</div>
      </a>
      <a @click='gotoContactUs'>
        <div class="c-menu__container-list" :class="{ ' c-menu__container-list_active': currentPage == 'joinsparrow' }">
          Contact Us</div>
      </a>
      <Search></Search>
    </div>
    <div class="c-menu__container c-menu__container_mobile">
      <div class="c-menu__container-burger" v-if="!searchStatus">
        <img src="@/assets/images/menu-burger.svg" @click="toggleMenu()" v-if="!showMenu" />
        <img src="@/assets/images/close-button.svg" @click="toggleMenu()" v-else />
        <div class="c-menu__container-all" :class="{ 'c-menu__container-all_showed': showMenu }">
          <div class="c-menu__container-all-list" v-for="(dt, index) in allList" :key="index">
            <div class="c-menu__container-all-list-title"
              :class="{ 'c-menu__container-all-list-title_active': dt.subMenuStatus == true }"
              @click="activeSubMenu(index)">
              <h3 class="c-menu__container-all-list-title-text"
                :class="{ 'c-menu__container-all-list-title-text_bold': dt.subMenuStatus == true }">{{ dt.title }}</h3>
            </div>
            <div class="c-menu__container-all-submenu" v-if="dt.subMenuStatus == true">
              <div class="c-menu__container-all-submenu-list" v-for="(detail, idx) in dt.subMenu" :key="idx"
                @click="openLink(detail.link), disableHighLight()">
                <h3 class="c-menu__container-all-submenu-list-title-text"
                  :class="{ 'c-menu__container-all-submenu-list-title-text_active': detail.currentActive == true }">
                  {{ detail.title }}</h3>
              </div>
            </div>
          </div>
          <!-- <div class="c-menu__container-all-list" @click="openLink('/signin')">
            <div class="c-menu__container-all-list-title">
              <div class="c-menu__container-all-list-title-text">
                Sign In
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="c-menu__container-price" v-if="!searchStatus">
        <p class="c-menu__container-price-text" v-for="(dt, index) in spotPriceData" :key="index">
          <span class="c-menu__container-price-text_bold">
            {{ getCoinFromPair(dt.symbol) }}
          </span>
          {{ dt.spot }}
          <span class="c-menu__container-price-text-divider" v-if="index != spotPriceData.length - 1">|</span>
        </p>
      </div>
      <Search></Search>
    </div>
  </div>
</template>
<script>
import Search from '@/components/Search.vue'
export default {
  name: 'Menu',
  components: {
    Search,
  },
  data() {
    return {
      currentPage: '',
      screenWidth: window.innerWidth,
      showMenu: false,
      allList: [],
      allListMobile: [],
      searchStatus: false
    }
  },
  mounted() {
    this.getScreenWidth()
    this.allMenuList()
  },
  created() {
    this.$eventHub.$on('pass-route-name', (val) => {
      this.currentPage = val
    })
    this.$eventHub.$on('status-search', (val) => {
      this.searchStatus = val
    });
  },
  methods: {
    gotoContactUs() {
      window.location.href = this.contactUsUrl();
    },
    getScreenWidth() {
      let _this = this
      window.onresize = window.onload = function () {
        _this.screenWidth = this.innerWidth;
        _this.showMenu = false
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    allMenuList() {
      let menuList = [
        {
          title: 'About',
          subMenu: [
            {
              title: 'Who We Are',
              link: '/about/who-we-are',
              currentActive: false
            },
            {
              title: 'Vision & Mission',
              link: '/about/vision',
              currentActive: false
            },
            {
              title: 'Core Values',
              link: '/about/core-values',
              currentActive: false
            },
            {
              title: 'Milestones & Achievements',
              link: '/about/milestone',
              currentActive: false
            },
            {
              title: 'Why Sparrow',
              link: '/about/why-sparrow',
              currentActive: false
            },
          ],
          subMenuStatus: false
        },
        {
          title: 'Personal & Services',
          subMenu: [
            {
              title: 'Institutions',
              link: '/institutional/wealth',
              currentActive: false
            },
            {
              title: 'Personal',
              link: '/consumer/individual',
              currentActive: false
            },
            {
              title: 'Getting Started',
              hiddenTitle: 'Getting Started (Institutional)',
              link: '/institutional/getting-started',
              currentActive: false
            }
          ],
          subMenuStatus: false
        },
        {
          title: 'People at Sparrow',
          subMenu: [
            {
              title: 'Meet The Team',
              link: '/people-at-sparrow/team',
              currentActive: false
            },
            {
              title: 'Join Sparrow',
              link: '/people-at-sparrow/join-sparrow',
              currentActive: false
            },
          ],
          subMenuStatus: false
        },
        {
          title: 'Legal & Compliance',
          subMenu: [
            {
              title: 'Disclaimers & Risk Warning',
              link: '/legal/disclaimers-and-risk-warning',
              currentActive: false
            },
            {
              title: 'Regulation',
              link: '/legal/regulation',
              currentActive: false
            },
            {
              title: 'User Agreement',
              link: '/legal/user-agreement',
              currentActive: false
            },
            {
              title: 'Privacy Policies',
              link: '/legal/privacy-policies',
              currentActive: false
            },
          ],
          subMenuStatus: false
        },
        {
          title: 'Contact Us',
          subMenu: [
            {
              title: 'Contact Us',
              link: this.contactUsUrl(),
              currentActive: false
            },
            // {
            //   title: 'Join Sparrow',
            //   link: '/contact-us/join-sparrow',
            //   currentActive: false
            // },
          ],
          subMenuStatus: false
        },
      ]

      this.allList = menuList
    },
    resetAllSubMenuStatus() {
      this.allList.forEach(dt => {
        dt.subMenuStatus = false
      })
    },
    activeSubMenu(index) {
      if (this.allList[index].subMenuStatus == true) {
        this.allList[index].subMenuStatus = false
      } else {
        this.resetAllSubMenuStatus()
        this.allList[index].subMenuStatus = true
      }
    },
    getCoinFromPair: function (pair) {
      return pair.split('-')[0]
    },
    highLightMenu() {
      let _this = this
      this.allList.forEach((data) => {
        data.subMenu.forEach((detail) => {
          if (detail.hiddenTitle) {
            if (detail.hiddenTitle == _this.routeName) {
              data.subMenuStatus = true
              detail.currentActive = true
            }
          } else {
            if (detail.title == _this.routeName) {
              data.subMenuStatus = true
              detail.currentActive = true
            }
          }
        })
      })
    },
    disableHighLight() {
      let _this = this
      this.allList.forEach((data) => {
        if (_this.$route.name == 'Home') {
          data.subMenuStatus = false
        }
        data.subMenu.forEach((detail) => {
          detail.currentActive = false
        })
      })
    }
  },
  watch: {
    showMenu(val) {
      var body = document.getElementsByTagName("BODY")[0]
      if (val == true) {
        body.style.overflow = 'hidden'
      } else {
        body.style.overflow = 'initial'
      }
    },
    routeName(val) {
      if (val == 'Home') {
        this.disableHighLight()
      }
      this.highLightMenu()
    }
  },
  computed: {
    spotPriceData() {
      return this.$store.getters.getPriceWs
    },
    routeName() {
      return this.$route.name
    }
  }
}
</script>
