<template>
  <div class="c-header">
    <div class="c-header__content">
      <div class="c-header__content-logo" @click="goToHome()">
        <img class="c-header__content-logo-img" src="../assets/images/logo.svg" />
      </div>
      <!-- <div class="c-header__content-currency">
        <div class="c-header__content-currency-container" v-for="(dt,index) in spotPriceData" :key="index">
          <div class="c-header__content-currency-container-coin">
            <img class="c-header__content-currency-container-coin-icon" :src="dt.icon"/>
            <div class="c-header__content-currency-container-coin-name">
              {{getCoinFromPair(dt.symbol)}}
            </div>
          </div>
          <div class="c-header__content-currency-container-amount" :class="{'c-header__content-currency-container-amount-no-border':index==spotPriceData.length-1}">
            {{dt.spot}}
          </div>
        </div>
      </div> -->
      <div class="c-header__content-menu" v-if="!isLogin">
        <button class="c-header__content-menu-signin" @click="signIn">Sign In</button>
        <button class="c-header__content-menu-register" @click="registerAccount">Register</button>
      </div>
      <div class="c-header__content-menu" v-if="isLogin">
        <button class="c-header__content-menu-account" @click="myAccount">My Account</button>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  name: 'Header',
  data() {
    return {
      isLogin: false,
      showLanguageDropdown: false,
      dataLanguageDropdown: '',
      configJson: require('../../config.json'),
      date: '',
      time: '',
      utcDate: '',
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      allowanceCoin: [
        'BTC-SP$',
        'ETH-SP$',
        'EOS-SP$',
      ]
    }
  },
  mounted() {
    this.checkIsLogin();
  },
  methods: {
    showDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    selectValue(item) {
      this.hideDropdown();
      this.dataLanguageDropdown = item
    },
    hideDropdown() {
      this.showLanguageDropdown = false;
    },
    registerAccount() {
      // this.redirectUrl(this.configJson.desktop_url+"/signup")
      window.location = '/dashboard/register'
    },
    myAccount() {
      window.location = '/dashboard/'
    },
    signIn() {
      window.location = '/dashboard/login'
    },
    goToHome() {
      this.$eventHub.$emit('pass-route-name', 'home');
      this.$router.push('/').catch(() => { });
    },
    getCoinFromPair: function (pair) {
      return pair.split('-')[0]
    },
    filterSpotData: function () {

    },
    checkIsLogin() {
      const uid = localStorage.getItem('UID');
      const refresh_token = localStorage.getItem('refresh_token');
      if (!uid || !refresh_token) {
        this.isLogin = false;
      } else {
        this.isLogin = true;
      }
    },
    // BUAT HEADER ALPHA
    // setDateTime(){
    //   let today = new Date()
    //   this.date = today.getDate() + '-' + (today.getMonth()+1) + '-' + today.getFullYear()
    //   this.time = today.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
    // }
    //
  },
  computed: {
    spotPriceData() {
      return this.$store.getters.getPriceWs
    },

    token: function () {
      return this.$store.getters.token;
    },
  },
  watch: {

  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>
